<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width">

      <div class="page__min-container">
        <p class="text__head">
          Бренд-ролик
        </p>
        <div class="video">
          <img class="video__bg-img" src="@/assets/img/office-identity-page/slides/office-layout.png" alt="layout">
          <div class="video__wrapper">
            <video autoplay loop controls>
              <source src="@/assets/img/video-and-animation-page/brand-video.mp4">
            </video>
<!--            <img class="content-img" :src="require(`@/assets/img/video-and-animation-page/brand-video.gif`)" alt="">-->
          </div>
        </div>
      </div>

      <div class="page__link-block download-block">
        <icon-download
          v-for="icon in icons"
          :key="icon.icon"
          :url="icon.url"
          :icon="icon.icon"
        ></icon-download>
      </div>
    </div>



    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import IconDownload from "../../../components/Elements/IconDownload/IconDownload"

export default {
  components: {
    IconDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/interior",
          text: "Интерьер"
        },
        next: {
          url: "/",
          text: "Главная"
        }
      },
      icons: [
        {
          url: "../download-file/12-Video/We-are-Steor.mp4",
          icon: "icon-mp4.svg"
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";


.video {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__bg-img {
    width: 100%;
  }
  &__wrapper {
    position: absolute;
    top: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    & video {
      width: 100%;
    }
    & img {
      width: 100%;
    }
  }
}

.text {
  &__head {
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0;
    margin-bottom: 32px;
  }
}

.page {
  margin-bottom: 192px;
  &__full-width {
    display: flex;
    align-items: center;
  }
  &__min-container {
    margin-bottom: 0;
  }

  &__link-block {
    display: flex;
    margin-top: -110px;
    margin-left: 32px;
  }
}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .video {
    &__wrapper {
      top: 15px;
      padding: 0 16px 16px;
    }
  }


  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    margin-bottom: 161px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}

</style>
